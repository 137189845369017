/* @import url(https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,400,300,700); */
@import url("1.css");
@import url("2.css");
@import url("3.css");

/* Colours */
$twitter:        #6dabda;
$linkedin:       #2b77b1;
$google-plus:    #c95644;
$github:         #f6c2a4;
$stack-overflow: #c98b3b;
$rss:            #eea33a;

$darkest:        #000000;

$darker:         #212120;
$dark:           #1E1E1E;
/*
another two dark value are good too:
$dark:           #2F2F2F;
$dark:           #21211E;
*/


$medium:         #3B3B35;
$light-medium:   #969696;
$dark-blue:      #215A6D;
$light-blue:     #3CA2A2;
$green:          #92C7A3;
$good-green:	 #B3D169;
$light:          #DFECE6;
$white:          #FFFFFF;
$word-color:     #CCCCCC;
$word-color1:    #A3A3A3;
$dark-purple:    #300030;
$medium-purple:  #480048;
$regular-purple: #601848;
$red:            #C04848;
$orange:         #F07241;

/* Responsive sizes */
$screen-sm-min: 768px;
$screen-md-min: 992px;
$screen-lg-min: 1200px;

@font-face {font-family: "Dancing Script";
font-style: normal;
font-weight: 400;
src: local("Dancing Script"), local("DancingScript"), url("https://fonts.gstatic.com/s/dancingscript/v7/DK0eTGXiZjN6yA8zAEyM2Ud0sm1ffa_JvZxsF_BEwQk.woff2") format("woff2");
unicode-range: U+0-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

/* Globals */

html {
	height: 100%;
	overflow-x: hidden;
}

body {
	font-family: arial,sans-serif; 
	font-weight: normal;
	background-color: $dark;
	color: $word-color1;
	font-size: 16px;
	height: 100%;
	width: 100%
}

strong {
    color: #558000;
}

a, a:active {
	color: $light-blue;
	text-decoration: none;
}

a:hover {
	color: $dark-blue;
	text-decoration: none;
}

ul {
    list-style-type: none;
}

.navbar, .nav {
	background-color: $dark-blue;

	.navbar-toggle {
		margin-top: 10px;
		padding-top: 0px;
		padding-bottom: 20px;
	}

	li {

		a, a:active {
			color: $white;
		}

		a:hover {
			color: $dark-blue;
			text-decoration: none;
		}
	}
}

.clearfix:after {
   content: " ";
   display: block;
   height: 0;
   clear: both;
}

.header {
	font-size: 32px;
	text-align: center;
	margin-top: 20px;
	margin-left: -0%;
	width: 100%;

	@media (min-width: $screen-sm-min) {
		font-size: 62px;
		margin-left: -10%;
		width: 120%;
	}
}

.menu-button {
	color: $white;
	font-size: 24px;
	position: fixed;
	top: 10px;
	left: 20px;
	cursor: pointer;
	line-height: 1em;
	padding: 10px;
	background-color: $dark;
	z-index: 100;
	@media (min-width: $screen-sm-min) {
		top: 20px;
		left: 40px;
	}
}



.my-home {
    color: $white;
    font-size: 16px;
    position: fixed;
    top: 60px;
    left: 30px;
    cursor: pointer;
    line-height: 1em;
    padding: 2px;
    z-index: 80;
    @media (min-width: $screen-sm-min) {
    top: 110px;
    left: 80px;
    }
}




.my-papers {
    color: $white;
    font-size: 16px;
    position: fixed;
    top: 85px;
    left: 30px;
    cursor: pointer;
    line-height: 1em;
    padding: 2px;
    z-index: 80;
    @media (min-width: $screen-sm-min) {
    top: 140px;
    left: 80px;
    }
}


.my-search {
    color: $white;
    font-size: 16px;
    position: fixed;
    top: 110px;
    left: 30px;
    cursor: pointer;
    line-height: 1em;
    padding: 2px;
    z-index: 80;
    @media (min-width: $screen-sm-min) {
    top: 170px;
    left: 80px;
    }
}

.my-archive {
    color: $white;
    font-size: 16px;
    position: fixed;
    top: 135px;
    left: 30px;
    cursor: pointer;
    line-height: 1em;
    padding: 2px;
    z-index: 80;
    @media (min-width: $screen-sm-min) {
    top: 200px;
    left: 80px;
    }
}


.my-categories {
    color: $white;
    font-size: 16px;
    position: fixed;
    top: 160px;
    left: 30px;
    cursor: pointer;
    line-height: 1em;
    padding: 2px;
    z-index: 80;
    @media (min-width: $screen-sm-min) {
    top: 230px;
    left: 80px;
    }
}




.myclass {
    color: $green;
    font-size: 24px;
    width: 10px;
    height: 10px;
    top: 20px;
    left: 20px;
    cursor :pointer;
    line-height: 30em;
    padding: 30px;
    background-color: $red;
    z-index: 30;
    @media (min-width: $screen-sm-min) {
        top: 20px;
        left: 20px;
    }
}

.content-panel {
    /*新增3行*/
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    /*新增3行*/
	background-color: $darker;
	padding-top: 20px;
	padding-bottom: 20px;
	/*padding-left: 30px;*/
	/*padding-right: 30px;*/
	margin-top: 20px;
	box-shadow: 0px 0px 0px 0px, 0px 6px 12px rgba(0, 0, 0, 0.2);
	border-bottom: 5px solid $light-blue;
    @media (min-width: $screen-sm-min) {
        width: 980px;
    }

}

.main {
    width: 100%;
}



#my-menu {

	p {
		margin-bottom: 5px;
	}

	a, a:active {
		color: $white;
	}

	a:hover {
		color: $light-blue;
	}

	.pages {

		.fa {
			margin-right: 10px;
		}
    }
	
}

.links {
	margin-top: 50px;
	text-align: center;

	i {
		padding-left: 5px;
		padding-right: 5px;

		@media (min-width: $screen-sm-min) {
			padding-left: 10px;
			padding-right: 10px;
		}
	}

	.fa {
		-webkit-transition: 0.3s;
		-moz-transition: 0.3s;
		transition: 0.3s;
	}

	.fa-twitter:hover {
		color: $twitter;
	}

	.fa-linkedin:hover {
		color: $linkedin;
	}

	.fa-google-plus:hover {
		color: $google-plus;
	}

	.fa-github-alt:hover {
		color: $github;
	}

	.fa-stack-overflow:hover {
		color: $stack-overflow;
	}

	.fa-rss:hover {
		color: $rss;
	}
}

/* Index */

.index {
	height: 100%;
	max-height: 100%;
	width: 100%;
	padding: 0px;

	@media (min-width: $screen-sm-min) {
		width: 700px;
		max-width:700px;
	}
    
/*    @media (min-width: $screen-lg-min) {*/
/*       width: 100%;*/
/*       max-width:1000px;*/
/*    }*/


	.header {
		margin-top: 10px;
		margin-left: 0%;
		margin-bottom: 0px;
		font-size: 32px;
		width: 100%;

		@media (min-width: $screen-lg-min) {
			margin-left: -10%;
			width: 120%;
		}
	}

	.categories {
		font-size: 22px;
	}

	.content-panel:nth-of-type(1) {
		border-bottom: 10px solid $light-blue;
	}

	.author-header {
		//font-family: '文泉驿等宽正黑';
		color: $light-blue;
//		margin-top: 0px;
//		padding-top: 15px;
//		font-size: 30px;
//
       font: 70px Dancing Script;
       text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);

		@media (min-width: $screen-sm-min) {
			font-size: 52px;
			margin-top: 0px;
		}

		@media (min-width: $screen-md-min) {
			font-size: 60px;
			margin-top: -20px;
		}
	}

	.row {
		height: 100%;
		max-height: 100%;
		margin-top: 60px;
		margin-left: 0px;
		margin-right: 0px;
	}

	.gravatar {
		width: 100%;
		margin-top: -80px;
		text-align: center;
	}

	.author-text {
		text-align: center;
		margin-top: 30px;
		font-size: 16px;

		b {
			font-size: 32px;
		}
	}

	.links {
		font-size: 2em;
	}

	.articles {
		text-align: center;
		font-size: 20px;

		h2 {
			margin-bottom: 30px;
			color: $light-blue;
			font-size: 26px;
		}

		ul {

			padding: 0px;

			li {
				margin-bottom: 8px;
				font-size: 18px;

				a:hover {
					color: $light-medium;
				}

				small {
					font-size: 8px;
					margin-top: -10px;
					padding-top: 0px;
					color: $light-medium;
				}

			}

			li:nth-last-of-type(1) {

				small {
					font-size: 16px;
				}

			}
		}

		a, a:active {
			color: $white;
			white-space: nowrap;
		}

		a:hover {
			color: $light-blue;
		}
	}

	.copyright {
		margin-top: 50px;
		text-align: center;
	}

	.links {
		a, a:active {
			color: #FFFFFF;
		}
	}

	.menu {
		margin-top: 250px;
		margin-bottom: 20px;
		font-size: 20px;
		position: absolute;
		bottom: 0px;

		a, a:active {
			color: #FFFFFF;
		}

		a:hover {
			color: $light-blue;
		}

		ul {
			margin-bottom: 30px;
			padding-left: 10px;

			ul {
				padding-left: 50px;
				list-style: none;
				margin-left: 0;
				text-indent: -0.6em;
			}
		}
	}

}

.all-posts {
	margin-left: 0px;
	margin-right: 0px;

	@media (min-width: $screen-sm-min) {
		#search-container {
			padding-top: 75px;
			padding-bottom: 150px;
		}
	}

	@media (min-width: $screen-md-min) {
		margin-left: -15px;
		margin-right: -15px;

		#search-container {
			padding-bottom: 300px;
		}
	}

	#search-input {
		position:relative;
		width: 100%;
		padding:6px 15px 6px 30px;
		margin: 2px;
		margin-top: 50px;

		color:$dark;
		background-color:$white;

		border:1px solid #adc5cf;
		border-radius:15px;
		-moz-border-radius:15px;
		-webkit-border-radius:15px;
	}

	#search-input:focus {
		border: none;
  }

	.content-panel {
		padding-left: 10px;
		padding-right: 10px;
		@media (min-width: $screen-md-min) {
			padding-left: 30px;
			padding-right: 30px;
		}
	}
}


.single {
	height:100%;
	max-height: 100%;

	h1, h2, h3, h4, h5, h6 {
		font-family: '文泉驿等宽正黑';
		margin-bottom: 15px;
	}
    
    h1 {
        font-size: 36px;
        /* 这里用的是iPhone 6 portrait,iphone6标准显示*/
        @media only screen and (min-device-width: 375px)
          and (max-device-width: 667px)
          and (orientation: portrait)
          and (-webkit-min-device-pixel-ratio: 2)
        {
            font-size: 2.25em;
        }
    }

	h2 {
		font-size: 28px;

        /* 这里用的是iPhone 6 portrait,iphone6标准显示*/
        @media only screen and (min-device-width: 375px)
          and (max-device-width: 667px)
          and (orientation: portrait)
          and (-webkit-min-device-pixel-ratio: 2)
        {
            font-size: 1.75em;
        }
	}

	h3 {
		font-size: 24px;

        /* 这里用的是iPhone 6 portrait,iphone6标准显示*/
        @media only screen and (min-device-width: 375px)
          and (max-device-width: 667px)
            and (orientation: portrait)
              and (-webkit-min-device-pixel-ratio: 2)
              {
                  font-size: 1.5em;
              }
	}

	p {
		margin-bottom: 10px;

		@media (min-width: $screen-sm-min) {
			margin-bottom: 15px;
		}
	}

	.menu {

		background-color: $dark-blue;

		a, a:active {
			color: $white;
		}

		a:hover {
			color: $light-blue;
		}

		h4 {
			margin-top: 20px;
		}

		.share {
			margin-top: 20px;

			h4 {
				margin-bottom: 10px;
			}
		}

		.home {
			margin-top: 10px;
			font-size: 16px;

			a {
				color: $white;
			}
		}

		.related {
			ul {
				padding: 0px;
			}
			li {
				margin-bottom: 10px;
			}
		}
	}

	.article {
		width: 100%;
		margin-left: 0%;
		padding: 0px;

		@media (min-width: $screen-sm-min) {
			width: 100%;
			/*width: 90%;*/
			/*margin-left: 2%;*/
		}

		@media (min-width: $screen-md-min) {
			width: 70%;
			margin-left: 15%;
		}

		@media (min-width: $screen-lg-min) {
			/*width: 50%;
			margin-left: 25%;*/
		}

		.thumb {
			margin-top: 20px;
			margin-bottom: 0px;
			text-align: center;

			img {
				height: 50px;
				@media (min-width: $screen-sm-min) {
					height: 70px;
				}
			}
		}

		.author {
			font-size: 20px;
			text-align: center;
			margin-bottom: 0px;
		}

		.read-time {
			font-size: 16px;
			text-align: center;
			margin-bottom: 10px;
			font-weight: 400;
			@media (min-width: $screen-sm-min) {
				margin-bottom: 40px;
			}
		}

		.content {
			font-size: 18px;
			line-height: 1.6;
			/*padding-left: 15px;*/
			/*padding-right: 15px;*/
			padding-top: 10px;
			padding-bottom: 10px;
            /*width: 980px;*/

            /* iPhone 6 portrait,iphone6标准视图*/
            @media only screen and (min-device-width: 375px)
              and (max-device-width: 667px)
              and (orientation: portrait)
              and (-webkit-min-device-pixel-ratio: 2)
            {
                font-size: 14px;
            }

			@media (min-width: $screen-sm-min) {
				font-size:16px;
				/*padding-left: 30px;
				padding-right: 30px;*/
				padding-top: 40px;
				padding-bottom: 20px;
			}

			.series {
				margin-bottom: 50px;
			}

			img {
			max-width: 100%;
			padding-top: 10px;
            padding-left: 0px;
            padding-right: 0px;
			display: block;
            margin-left: auto;
            margin-right: auto;
			}

			ul {
				list-style-type: disc;
			}

			ol {
				list-style-type: decimal;
			}

			blockquote {
				position: relative;
				width: 100%;
				margin-left: -15px;
				padding-right: 10px;
				background-color: #303030;
				color: $word-color1;
				box-sizing: content-box;
				border: none;
				font-size: 16px;

				@media (min-width: $screen-sm-min) {
					margin-left: 0px;
					padding-left: 0px;
					padding-right: 0px;
				}

				p {
			    margin: 0;
			    padding: 15px;
				z-index: 0;
				}

				:before {
    		    font-family: "文泉驿等宽正黑";
			    color: $light-medium;
			    /*content: "“";*/
			    position: absolute;
			    top: 15px;
			    left: 15px;
			    font-size: 65px;
			    font-weight: bold;
				}

			}

			.alignright {
				float: right;
			}

			.aligncenter {
			    display: block;
			    margin: 5px auto 5px auto;
			}

            
            
			figure.highlight, div.highlighter-rouge {
				width: 100%;
				/*margin-left: -15px;*/
				/*padding-right: 10px;*/
				box-sizing: content-box;

				@media (min-width: $screen-sm-min) {
                    /*取消这2行*/
					/*margin-left: -30px;
					padding-right: 60px;*/
                    /*取消这2行*/
				}

				pre {
					width: 100%;
					font-family: '文泉驿等宽正黑';
					background-color: $darkest;
					color: $white;
					font-size: 12px;
					border: none;
					/*padding-left: 15px;*/
					/*padding-right: 15px;*/
					padding-top: 20px;
					padding-bottom: 20px;
					box-sizing: content-box;
					border-radius: 0;
					margin-bottom: 10px;
					margin-top: 10px;

					@media (min-width: $screen-sm-min) {
                        /*取消2行*/
						/*padding-left: 50px;
						padding-right: 10px;*/
                        /*取消2行*/
						margin-bottom: 15px;
						font-size: 15px;
					}
				}
			}

			p, ul, ol {

				code {
					font-family: '文泉驿等宽正黑';
					font-size: 85%;
					background-color: #996699;
					color: $darker;
					padding: 4px;
                    padding-top: 0.2em;
                    padding-bottom: 0.2em;
					vertical-align: middle;
                    code:after {
                              letter-spacing: -0.4em;
                              content: "\00a0";

                    }
				}

			}

			.tags {
				margin-top: 10px;
				margin-bottom: 10px;
				text-align: center;

				a, a:active {
					color: $white;
				}

				a:hover {
					color: $light-blue;
				}

			}

			.share {
				margin-top: 20px;
				margin-bottom: 10px;
				text-align: center;

			}

		}

		.feedback {
			text-align: center;

			.fa-heart {
				color: $red;
			}

		}

		.comments {
		}

	}
}

.related {
	margin-top: 50px;
	text-align: center;

	.related-header {
		margin-top: -30px;
		margin-bottom: 20px;

		a {
			color: $white;
			font-size: 18px;
			width: auto;
			padding: 10px 20px;
			background-color: $darker;
			box-shadow: 0px 0px 0px 0px, 0px 6px 12px rgba(0, 0, 0, 0.2);
		}

		a:hover {
			color: $dark-blue;
		}
	}

	.title {
		padding-top:20px;
		font-size: 24px;
		margin-bottom: 20px;

		@media (min-width: $screen-sm-min) {
			font-size: 36px;
		}

		a {
			color: $white;
		}

		a:hover {
			color: $light;
		}
	}

	p {
		margin-bottom: 0px;
	}

	.previous-next {
		width: 50%;

		a {
			color: $white;
		}

		a:hover {
			color: $light;
		}
	}

	.next {
		float: right;
		text-align: right;
	}

	.previous {
		float: left;
		text-align: left;
	}

	.date {
		color: $light-medium;
	}
}

.search-hr {
	width: 700px;
	border-color: $light-medium;
}

.error {
	.error-text {
		margin-top: 30px;

		a {
			color: $light-blue;
		}
	}

	.links {
		font-size: 1em;
	}
}

.footer {
	width: 100%;
	margin-left: 0%;

	.fa-heart {
		color: $red;
	}

	@media (min-width: $screen-sm-min) {
		width: 90%;
		margin-left: 5%;
	}

	@media (min-width: $screen-md-min) {
		width: 80%;
		margin-left: 10%;
	}

	@media (min-width: $screen-lg-min) {
		width: 70%;
		/*margin-left: 15%;*/
		margin-left: auto;
        margin-right: auto;
	}

	div {
		color: $light-medium;
		text-align: center;
		margin-top: 20px;
		margin-bottom: 20px;
	}
}

.btn-categories {
	background-color: $light-blue;
	border-color: $light-blue;
	color: $white;
	&:hover {
		background-color: $dark-blue;
		border-color: $dark-blue;
		color: $white;
	}
	&:active {
		background-color: $dark-blue;
		border-color: $dark-blue;
		color: $white;
	}
	&:focus {
		background-color: $dark-blue;
		border-color: $dark-blue;
		color: $white;
	}
}

.dropdown-menu {
	background-color: $light-blue;
	color: $white;
	> li > a {
		background-color: $light-blue;
		color: $white;
		&:hover {
			background-color: $dark-blue;
			color: $white;
		}
	}
}
